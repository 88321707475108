@import 'fonts';
@import 'home';
@import 'section';
@import 'header';
@import 'sidenav';
@import 'footer';
@import 'media';

$orange: #ffab4c;
$blue: #5ebbbf;

* {
  box-sizing: border-box;
  background-color: #171c28;
  font-family: $main;
}

footer {
  height: 10vh;
  position: relative;

  .up-icon {
    position: absolute;
    right: 3rem;
    bottom: 2rem;
    font-size: 1.5rem;
    color: $orange;
  }
  .up-icon:hover {
    animation: 1s bounce;
    cursor: pointer;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

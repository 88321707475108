.hide {
  opacity: 0;
}

.home-section {
  min-height: 80vh;
  // animation: fadeIn linear 2s;
  // -webkit-animation: fadeIn linear 2s;
  // -moz-animation: fadeIn linear 2s;
  // -o-animation: fadeIn linear 2s;
  // -ms-animation: fadeIn linear 2s;

  .intro-container {
    width: 50%;
    margin: 15vh auto;
    height: fit-content;
    color: white;
  }
  h1 {
    font-weight: 200;
    font-size: 1.25rem;
    padding-bottom: 0.8rem;
    margin-bottom: 0;
    color: $orange;
    font-family: $mono;
    // overflow: hidden;
    // border-right: 0.15em solid $orange;
    // white-space: nowrap;
    // animation: typing 5s steps(60, end), blink-caret 0.75s step-end infinite;
  }
  h2 {
    margin-top: 0.75rem;
    font-weight: 300;
    font-size: 1.75rem;
    text-align: justify;
    letter-spacing: -0.5px;
    margin: 0;

    span {
      font-weight: 600;
      font-size: 3rem;
      letter-spacing: 2.5px;
      margin: 0;
      padding: 0;
      // animation: fadeIn linear 2s;
      // color: #ffab4c;
    }
  }
  h3 {
    font-weight: 300;
    font-size: 1.25rem;
    text-align: justify;
    .arrow-icon {
      font-size: 30px;
      padding: 0 6px;
      vertical-align: -0.25em;
    }
    span {
      border-bottom: 2px solid #ffab4c;
      a {
        text-decoration: none;
        color: white;
        letter-spacing: 1px;
        transition: all 0.5s ease-in-out;
      }
      a:hover {
        box-shadow: inset 0 -33px 0 0 $orange;
        color: #171c28;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

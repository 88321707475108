@media screen and (max-width: 600px) {
  header {
    nav {
      margin-right: 1.5rem;
      ul {
        flex-direction: column;
        align-items: flex-end;
        margin-right: 0;
        line-height: 0.7;
      }
    }
  }

  .home-section {
    min-height: 100vh;

    .intro-container {
      width: 80%;
      h1 {
        font-size: 1rem;
      }
      h2 {
        font-size: 1.3rem;

        span {
          font-size: 2.2rem;
        }
      }
      h3 {
        font-size: 1.2rem;
      }
    }
  }
  section {
    padding: 3rem 0;
  }

  .section-container {
    width: 75%;
    flex-direction: column;
    margin: 0 auto;

    .section-subheading {
      width: 100%;
      text-align: left;
      margin-bottom: 2rem;
    }
    .section-content {
      width: 100%;
      font-size: 0.9rem;
      text-align: justify;
    }

    .skills {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 2.5rem;
      padding-left: 0;

      .skills-box {
        width: 50%;
      }
    }

    .projects {
      .project-container {
        height: fit-content;
        .project-top {
          flex-direction: column;
        }
        .mockup {
          width: 90%;
          height: 50%;
        }
        .project-title {
          width: 100%;
        }
        .project-links {
          top: 0.5rem;
          right: 0.5rem;
          flex-direction: column;
          row-gap: 0.5rem;
        }
      }
    }
  }
  .contact {
    .section-content {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 1rem;
      padding-left: 0.5rem;
    }
  }
  .side-nav {
    .social-icon {
      font-size: 20px;
    }
  }
}

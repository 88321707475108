section {
  // min-height: 80vh;
  padding: 6rem 0;
}
.section-container {
  display: flex;
  width: 70%;
  justify-content: center;
  margin: 0 auto;

  .section-subheading {
    width: 25%;
    color: $orange;
    font-weight: 600;
    letter-spacing: 3px;
    font-size: 1.2rem;
    text-align: right;
    padding-right: 2rem;
  }
  .section-content {
    width: 75%;
    color: white;
    letter-spacing: 1px;

    p {
      margin: 0;
    }
  }
  .about {
    line-height: 1.6;
    letter-spacing: 1px;
    text-align: left;
    font-size: 0.98rem;
    font-weight: 300;
  }
  .skills {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2em;

    .skills-box {
      width: fit-content;
      text-align: left;

      h4 {
        margin-top: 0;
        letter-spacing: 3px;
      }

      ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
        font-weight: 200;
        font-size: 0.95rem;
        line-height: 1.6;
      }
    }
  }

  .projects {
    .project-container {
      margin-bottom: 4rem;
      position: relative;

      .project-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .mockup {
        width: 60%;

        img {
          width: 100%;
          overflow: hidden;
        }

        img:hover {
          transform: scale(1.05);
          cursor: pointer;
        }
      }
      .project-title {
        width: 40%;
        font-size: 1.2rem;
        font-weight: 300;
        color: white;
        padding-left: 1rem;

        h4 {
          margin: 0;
          font-weight: 600;
          letter-spacing: 3px;
        }
        .project-description {
          width: 100%;
          text-align: justify;
          font-size: 0.85rem;
          line-height: 1.75;
        }
      }

      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding-left: 1rem;

        li {
          padding: 0.2rem 0.5rem;
          margin: 0.5rem 0.5rem;
          border: 1px solid $orange;
          color: $orange;

          border-radius: 3px;
          font-size: 0.7rem;
          font-family: $mono;
          font-weight: 400;
        }
      }

      .project-links {
        display: flex;
        flex-direction: row;
        column-gap: 0.7rem;
        position: absolute;
        right: 0;
        top: 0;

        .project-icon {
          font-size: 1.2rem;
          color: white;
        }
        .project-icon:hover {
          transform: translateY(-3px);
          color: $orange;
        }
      }
    }
  }
}

#contact {
  padding: 0;
  margin-bottom: 3rem;
}
.contact {
  .section-content {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    column-gap: 3rem;
    padding-left: 2rem;
  }

  a {
    font-size: 1rem;
    font-family: $mono;
    color: white;
    transition: all 0.5s ease-in-out;
  }
  a:hover {
    box-shadow: inset 0 -33px 0 0 $orange;
    color: #171c28;
  }
}

header {
  display: flex;
  justify-content: right;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;

  nav {
    width: fit-content;
    margin-right: 4rem;

    ul {
      list-style: none;
      display: flex;
      justify-content: right;
      flex-direction: row;

      li {
        color: $orange;
        font-family: $mono;
        padding: 0.2rem 0.5rem;
        margin: 0.5rem 0.5rem;
      }
      li:hover {
        color: white;
        transform: translateY(-0.2rem);
        cursor: pointer;
      }

      .cv {
        border: 1px solid $orange;
        border-radius: 3px;
        padding: 0.2rem 0.7rem;
      }

      .cv:hover {
        border: 1px solid white;
      }

      a {
        text-decoration: none;
      }
    }
  }
}

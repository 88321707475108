.side-nav {
  position: fixed;
  bottom: 3rem;
  left: 1.5rem;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  .social-icon {
    font-size: 30px;
    margin: 0.5rem 0;
    color: white;
  }
  .social-icon:hover {
    transform: translateY(-3px);
    color: $orange;
  }
}
